import { dataConstants } from "redux/constants";
import Axios from "utils/axios";
import { getInitialData } from "./app.action";

export const manageHospitalSection = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_HOSPITAL_SECTION_REQUEST });                  
      const { data } = await Axios.put(`/hospital/section/${id}`, form);
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_SUCCESS,
        payload: {
          message: data?.message,
        }
      });  
      dispatch(getInitialData());    
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageHospitalSectionFee = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_HOSPITAL_SECTION_REQUEST });                  
      let _data;
      if(id){
        _data = await Axios.put(`/hospital/section/fee/${id}`, form);
      }else{
        _data = await Axios.post(`/hospital/section/fee`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_SUCCESS,
        payload: {
          message: data?.message,
        }
      });  
      dispatch(getInitialData());    
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageHospitalSectionCategoryFee = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_HOSPITAL_SECTION_REQUEST });                  
      let data = await Axios.post(`/hospital/section/category/fee/${id}`, form);      
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_SUCCESS,
        payload: {
          message: data?.message,
        }
      });  
      dispatch(getInitialData());    
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_HOSPITAL_SECTION_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const manageUser = (id, form) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.MANAGE_USER_REQUEST });                  
      let _data;
      if(id){
        _data = await Axios.put(`/hospital/user/${id}`, form);
      }else{
        _data = await Axios.post(`/auth/hospital/register-user`, form);
      }
      const { data } = _data;
      dispatch({ 
        type: dataConstants.MANAGE_USER_SUCCESS,
        payload: {
          message: data?.message,
        }
      });  
      dispatch(getInitialData());    
    }catch(error){
      dispatch({ 
        type: dataConstants.MANAGE_USER_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAppointments = (page, limit, filter) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_APPOINTMENTS_REQUEST });                  
      let URL = `/hospital/appointment?page=${page}&limit=${limit}`;
      if(filter?.sectionId) URL += `&sectionId=${filter.sectionId}`;
      if(filter?.query) URL += `&query=${filter.query}`;
      if(filter?.appointmentNo) URL += `&appointmentNo=${filter.appointmentNo}`;
      if(filter?.token) URL += `&token=${filter.token}`;      
      if(filter?.gender) URL += `&gender=${filter?.gender}`;
      if(filter?.categoryId) URL += `&categoryId=${filter?.categoryId}`;      
      if(filter?.startDate && filter?.endDate) URL += `&startDate=${filter.startDate}&endDate=${filter.endDate}`;
      const { data } = await Axios.get(URL);
      dispatch({ 
        type: dataConstants.GET_APPOINTMENTS_SUCCESS,
        payload: data?.data
      });  
    }catch(error){      
      dispatch({ 
        type: dataConstants.GET_APPOINTMENTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getAppointment = (id) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.GET_APPOINTMENT_REQUEST });                  
      const { data } = await Axios.get(`/hospital/appointment/${id}`);
      dispatch({ 
        type: dataConstants.GET_APPOINTMENT_SUCCESS,
        payload: data?.data
      });  
    }catch(error){
      dispatch({ 
        type: dataConstants.GET_APPOINTMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const rescheduleAppointment = (id, form, fetch) => {
  return async dispatch => {
    try{
      dispatch({ type: dataConstants.RESCHEDULE_APPOINTMENT_REQUEST });                  
      const { data } = await Axios.put(`/hospital/appointment/${id}`, form);
      dispatch({ 
        type: dataConstants.RESCHEDULE_APPOINTMENT_SUCCESS,
        payload: {
          message: data?.message,        
        }
      });
      if(fetch) dispatch(getAppointment(id));
    }catch(error){
      dispatch({ 
        type: dataConstants.RESCHEDULE_APPOINTMENT_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}