import { reportConstants } from "redux/constants";
import Axios from "utils/axios";

export const getAppointmentReports = (form) => {
  return async dispatch => {
    try{
      dispatch({ type: reportConstants.GET_APPOINTMENT_REPORTS_REQUEST });
      let { data } = await Axios.post(`/report/hospital/appointment`, form);
      if(data?.data){
        dispatch({ 
          type: reportConstants.GET_APPOINTMENT_REPORTS_SUCCESS,
          payload: {
            reportData: data?.data?.report,
            appointmentData: data?.data?.appointment,
            startDate: data?.data.startDate,
            endDate: data?.data.endDate,
            categoryWiseAppointments: data?.data.categoryWiseAppointments,
          }
        });
      }
    }catch(error){
      dispatch({ 
        type: reportConstants.GET_APPOINTMENT_REPORTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}

export const getPatientsReports = (page, limit, form) => {
  return async dispatch => {
    try{
      dispatch({ type: reportConstants.GET_PATIENT_REPORTS_REQUEST });        
      let { data } = await Axios.post(`/report/hospital/patient?page=${page}&limit=${limit}`, form);
      if(data?.data){        
        dispatch({ 
          type: reportConstants.GET_PATIENT_REPORTS_SUCCESS,
          payload: data?.data
        });
      }
    }catch(error){
      dispatch({ 
        type: reportConstants.GET_PATIENT_REPORTS_FAILURE,
        payload: error.response.data.error 
      });
    }
  }
}