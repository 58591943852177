const states = [
  { "value": "andhra-pradesh", "label": "Andhra Pradesh" },
  { "value": "arunachal-pradesh", "label": "Arunachal Pradesh" },
  { "value": "assam", "label": "Assam" },
  { "value": "bihar", "label": "Bihar" },
  { "value": "chhattisgarh", "label": "Chhattisgarh" },
  { "value": "goa", "label": "Goa" },
  { "value": "gujarat", "label": "Gujarat" },
  { "value": "haryana", "label": "Haryana" },
  { "value": "himachal-pradesh", "label": "Himachal Pradesh" },
  { "value": "jammu-and-kashmir", "label": "Jammu and Kashmir" },
  { "value": "jharkhand", "label": "Jharkhand" },
  { "value": "karnataka", "label": "Karnataka" },
  { "value": "kerala", "label": "Kerala" },
  { "value": "madhya-pradesh", "label": "Madhya Pradesh" },
  { "value": "maharashtra", "label": "Maharashtra" },
  { "value": "manipur", "label": "Manipur" },
  { "value": "meghalaya", "label": "Meghalaya" },
  { "value": "mizoram", "label": "Mizoram" },
  { "value": "nagaland", "label": "Nagaland" },
  { "value": "odisha", "label": "Odisha" },
  { "value": "punjab", "label": "Punjab" },
  { "value": "rajasthan", "label": "Rajasthan" },
  { "value": "sikkim", "label": "Sikkim" },
  { "value": "tamil-nadu", "label": "Tamil Nadu" },
  { "value": "telangana", "label": "Telangana" },
  { "value": "tripura", "label": "Tripura" },
  { "value": "uttar-pradesh", "label": "Uttar Pradesh" },
  { "value": "uttarakhand", "label": "Uttarakhand" },
  { "value": "west-bengal", "label": "West Bengal" },
]

const genders = [
  { "value": "male", "label": "Male" },
  { "value": "female", "label": "Female" },
  { "value": "other", "label": "Others" },
]

const hospitalUserRoles = [
  { "value": 1, "label": "Admin" },
  { "value": 2, "label": "Doctor" },
  { "value": 3, "label": "Staff" },
  { "value": 4, "label": "Counter" },
  { "value": 5, "label": "Token Viewer" }
]

const bloodGroups = [
  { "value": "A+", "label": "A+" },
  { "value": "A-", "label": "A-" },
  { "value": "B+", "label": "B+" },
  { "value": "B-", "label": "B-" },
  { "value": "AB+", "label": "AB+" },
  { "value": "AB-", "label": "AB-" },
  { "value": "O+", "label": "O+" },
  { "value": "O-", "label": "O-" },
]

const sectionTypes = [
  { "value": "DEPARTMENT", "label": "Department" },
  { "value": "SERVICE", "label": "Service" },
  { "value": "FACILITY", "label": "Facility" }    
]

const paymentMethods = [
  { "value": "CASH", "label": "Cash", image: "/assets/cash.png" },
  { "value": "RAZORPAY", "label": "Razorpay", image: "/assets/razorpay.png" }, 
]

const tokenStatus = [
  { "value": "PENDING", "label": "Pending", "color": "warning" },
  { "value": "DIAGANOSED", "label": "Diagnosed", "color": "success"},
  { "value": "REJECTED", "label": "Rejected", "color": "error"},
  { "value": "EXPIRED", "label": "Expired", "color": "error"},
]

module.exports = {
  states,
  genders,
  hospitalUserRoles,
  bloodGroups,
  sectionTypes,
  paymentMethods,
  tokenStatus
}