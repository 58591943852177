import { apiConfig } from "config";

export const isMobile = () => (typeof window !== "undefined" ? window.innerWidth <= 600 : false);
export const isTab = () => (typeof window !== "undefined" ? window.innerWidth <= 1024 : false);

const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thusday", "Friday", "Saturday"]

export const formatDate = (date) => {
  let formattedDate;  
  if(typeof date === "object"){
    formattedDate = `${dayNames[date?.toDate().getDay()]}, ${monthNames[date?.toDate().getMonth()]} ${date?.toDate().getDate()}, ${date?.toDate().getFullYear()}`
  }else if(typeof date === "string"){
    formattedDate = `${monthNames[new Date(date).getMonth()]} ${new Date(date).getDate()}, ${new Date(date).getFullYear()}`
  }
  return formattedDate
}

export const scrollToTop = (window) =>{
  window.scrollTo({top: 0, left: 0, behavior: "smooth" })
}    

export const printINR = (cost) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }).format(cost);  
}

export const getAssetURL = (filename) => {
  return "/assets/"+filename;
}

export const generatePublicUrl = (file) => {
  if(file?.includes("http") || file?.includes("https")) return file;
  if(file?.split("/").length > 1) return file;
  return apiConfig.MEDIA_URL + "/" + file
}

export const formatDateAndTime = (date) => {
  let _date = new Date(date);
  return _date.toLocaleString("en-IN");  
}

export const addDays = (date, days) => {
  let result = new Date(date);
  result.setDate(result.getDate() + days);  
  return result;
}

export const calculateAge = (dob) => {  
  let _dob = new Date(dob);
  let diff_ms = Date.now() - _dob.getTime();
  let age_dt = new Date(diff_ms); 
  let ageInYears = Math.abs(age_dt.getUTCFullYear() - 1970);
  let ageInMonths = (ageInYears * 12) + age_dt.getUTCMonth();
  
  if (ageInYears < 1) {
    return ageInMonths.toString() + " Month";
  } else {
    return ageInYears.toString() + " Year";
  }
}

export const convertDateFormat = (dateString) => {
  let date = new Date(dateString);
  let day = String(date.getUTCDate()).padStart(2, '0');
  let month = String(date.getUTCMonth() + 1).padStart(2, '0'); //Months are zero based
  let year = date.getUTCFullYear();

  return `${day}-${month}-${year}`;
}

export const mobileNumberFormatter = (mobileNumber) => {  
  if (mobileNumber === null) {
    return "-";
  }
  // Remove all non-digit characters
  mobileNumber = mobileNumber.replace(/\D/g, '');
  if(mobileNumber.length > 10){
    mobileNumber = mobileNumber.slice(mobileNumber.length - 10);
  }
  return '+91-' + mobileNumber;
}

export const returnAgeString = (age) => {  
  let ageArray = age.split(":");
  let ageString = "";
  if (ageArray[0] && ageArray[0] !== "0") {
    ageString += ageArray[0] + " Year ";
  }
  if (ageArray[1] && ageArray[1] !== "0") {
    ageString += ageArray[1] + " Month ";
  }
  if (ageArray[2] && ageArray[2] !== "0") {
    ageString += ageArray[2] + " Day ";
  }
  return ageString;
}

export const shortenToken = (sectionCode, token) => {
  // XXZZYYMMDDTTTT  
  // TTTT - Token Number (Fixed 4)
  // return sectionCode + TTTT
  return String(sectionCode) + token.slice(-4);
}